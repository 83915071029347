import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }


const routes = [
  {
    path: '/',
    component: () => import('../views/Guide/index'),
    meta: {
      title: "三驱科技",
    },
  },
  {
    path: '/Home',
    component: () => import('../views/Home/index'),
    meta: {
      title: "三驱科技",
    },
  } ,{
    path: '/Map',
    component: () => import('../views/Map/index'),
    meta: {
      title: "三驱地图",
    },
  },{
    path: '/Base',
    component: () => import('../views/Base/index'),
    meta: {
      title: "三驱基地",
    },
  },{
    path: '/Dac',
    component: () => import('../views/Dac/index'),
    meta: {
      title: "3QDAC",
    },
  },{
    path: '/TenThousand',
    component: () => import('../views/TenThousand/index'),
    meta: {
      title: "万人添X",
    },
  },{
    path: '/Product',
    component: () => import('../views/Product/index'),
    meta: {
      title: "产品介绍",
    },
  },{
    path: '/ChessGame',
    component: () => import('../views/ChessGame/index'),
    meta: {
      title: "三一棋局",
    },
  },{
    path: '/Mobile',
    component: () => import('../views/Mobile/index'),
    meta: {
      title: "三驱科技",
    }
  }, {
    path: '/PutDisplay',
    component: () => import('../views/PutDisplay/index'),
    meta: {
      title: "3Q展览馆",
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 跳转到页首
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;

  let toPath = to.path;
  console.log(toPath.indexOf('/Mobile'));
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) && toPath.indexOf('/Mobile') == -1) {
    next('/Mobile');
  } else {
    next();
  }
  
})


export default router
